.container {
  display: flex;
}

.container > * {
  flex: 1;
  height: 100vh;
}

.container-auth-image {
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

.txt-powered-by {
  font-weight: normal;
  font-size: 0.8rem;
  color: var(--gray-300);
}
