.container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.container:not(.border) input[type="checkbox"] {
  border: 1px solid var(--white);
  border-radius: 7px;
}

.container label {
  font-weight: lighter;
  font-size: 1.05rem;
}

.container input {
  margin: 0;
}

.border {
  border: 1px solid var(--white);
  border-radius: 3px;
}

.text-error {
  font-size: small;
  text-align: left;
  color: var(--danger);
}
