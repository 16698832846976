.container {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.container label {
  font-weight: lighter;
  text-align: left;
  margin-bottom: 0.25rem;
  color: var(--white);
}

.with-icon {
  padding-left: 3rem;

  @media (max-width: 768px) {
    padding-left: 1rem;
  }
}

.btn-toggle-password-visibility {
  position: absolute;
  align-self: flex-end;
  top: 16px;
  right: 16px;
}

.btn-toggle-password-visibility:hover {
  cursor: pointer;
  opacity: 0.8;
}

.btn-toggle-password-visibility:active {
  opacity: 0.6;
}

.icon {
  position: absolute;
  left: 0.9rem;
  user-select: none;
}

.text-error {
  font-size: small;
  text-align: left;
  margin-top: 2px;
  color: var(--danger);
}

@media (max-width: 768px) {
  .container input {
    width: auto !important;
  }

  .icon {
    display: none;
  }
}
